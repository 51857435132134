@import '../reset.scss';

#catering-contents {
	font-family: $font_AppleSD;
	color: #000;

	.catering-main {
		.catering-main-banner {
			background: url('../image/catering/catering-main.png');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
			height: 400px;

			h1 {
				color: #fff;
				font-size: $h0;
				padding: 12rem 0 3rem 0;
			}
		}

		.catering-contents {
			max-width: $contents-maxwidth;
			margin: 3rem auto;

			.catering-contents-title {
				margin-bottom: 5rem;
			}

			.catering-contents-body {
				display: flex;

				.catering-contents-body-pricelist {
					display: flex;
					flex-wrap: wrap;
					flex-direction: row;
					flex: 1 1;

					margin: 0 3rem 0 0;

					.pricelist-item {
						$border-radius: 2vw;
						border-radius: $border-radius;
						box-shadow: 3px 3px 6px 0px #ccc;
						margin: 0.5rem;
						padding: 1rem;
						text-align: left;
						flex: 1 1;
						flex-basis: 25%;

						.pricelist-item-title {
							h3 {
								font-size: $h5;
								font-weight: bold;
								text-align: left;
								margin-bottom: 0.5rem;
							}
						}

						.pricelist-item-underline {
							width: 15%;
							height: 2px;
							background-color: #555;
							margin-bottom: 0.5rem;
						}

						.pricelist-item-detail {
							.pricelist-item-detail-description {
								p {
									font-size: $content-4;
									margin-bottom: 1rem;
									line-height: 0.8rem;
								}
							}

							.pricelist-item-detail-price-third {
								.pricelist-item-detail-price-third-item {
									display: flex;
									justify-content: space-between;

									h5 {
										font-size: $content-2;
									}

									p {
										font-size: $content-2;
									}
								}
							}

							.pricelist-item-detail-step {
								text-align: center;

								h5 {
									font-size: $content-2;
									font-weight: bold;
									margin-bottom: 0.5rem;
								}

								p {
									font-size: $content-4;
									margin-bottom: 0.3rem;
									line-height: 0.8rem;
								}
							}

							.pricelist-item-detail-plus {
								text-align: center;

								h5 {
									margin: 0;
								}
							}

							.pricelist-item-detail-menu {
								.pricelist-item-detail-menu-item {
									display: flex;
									justify-content: space-between;
									p {
										font-size: $content-2;
										font-weight: bold;
										margin-bottom: 0.1rem;
									}

									&:not(:first-child) {
										margin-left: 1rem;
									}
								}
							}
						}
					}
				}

				.catering-contents-body-form {
					flex: 1 1;

					.catering-contents-body-form-title {
						p {
							font-size: $content-3;
							font-weight: bold;
						}
					}

					Button {
						width: 100%;
					}
				}
			}
		}
	}

	@media screen and (max-width: 1199.98px) {
		/* 데스크탑 */
	}

	@media screen and (max-width: 991.98px) {
		/* 타블렛 */
	}

	@media screen and (max-width: 767.98px) {
		/* 스마트폰 가로 */
	}

	@media screen and (max-width: 575.98px) {
		/* 스마트폰 세로 */
	}
}
