@import '../reset.scss';

#findus-contents {
	font-family: $font_AppleSD;
	color: #000;

	.findus-main {
		background: url('../image/findus/findus-main.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		height: 100vh;

		h1 {
			color: #fff;
			font-size: $h0;
			padding: 9rem 0 3rem 0;
		}

		.findus-locator {
			margin: 0 5rem;
			display: flex;
			justify-content: space-between;

			.findus-locator-map {
				margin-right: 5vw;
				background: url('../image/findus/findus-map.png');
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center center;
				width: 55vw;
				height: 60vh;
			}
			.findus-locator-list {
				width: 25vw;
				height: 60vh;
				padding: 1rem;
				background-color: rgba(255, 255, 255, 0.2);
				overflow-y: scroll;

				.findus-locator-item {
					text-align: left;
					padding-bottom: 0.5rem;
					border-bottom: rgba(255, 255, 255, 0.5) 3px solid;

					.findus-locator-item-title {
						margin-bottom: 0.5rem;
						font-size: $h6;
						font-weight: bold;
					}

					p {
						margin-bottom: 0.4rem;
						font-size: $content-3;
						line-height: 1.2rem;
					}

					.findus-locator-item-phone {
						text-align: right;
					}
				}

				.findus-locator-item:not(:first-of-type) {
					margin: 1rem 0;
				}

				&::-webkit-scrollbar {
					background-color: #fff;
					width: 15px;
				}

				/* background of the scrollbar except button or resizer */
				&::-webkit-scrollbar-track {
					background-color: #fff;
				}

				/* scrollbar itself */
				&::-webkit-scrollbar-thumb {
					background-color: #c1c1c1;
					border-radius: 16px;
					border: 4px solid #fff;
				}

				/* set button(top and bottom of the scrollbar) */
				&::-webkit-scrollbar-button {
					display: none;
				}
			}
		}
	}

	@media screen and (max-width: 1199.98px) {
		/* 데스크탑 */
	}

	@media screen and (max-width: 991.98px) {
		/* 타블렛 */
	}

	@media screen and (max-width: 767.98px) {
		/* 스마트폰 가로 */
	}

	@media screen and (max-width: 575.98px) {
		/* 스마트폰 세로 */
	}
}
