@import '../reset.scss';

#navi-contents {
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	width: 100%;

	.nav-menu {
		width: 100%;

		.navbar {
			width: 100%;
			height: 100px;
			font-family: $font_Alata;
			background-color: rgba(0, 0, 0, 0.7);

			.navbar-nav {
				width: 100%;
				margin: 0 auto;
				justify-content: center;
				align-items: center;

				.nav-menu-text {
					display: flex;
					width: 480px;

					justify-content: flex-start;
					align-items: center;
				}

				.nav-menu-text,
				.left {
					justify-content: flex-end;
				}
				.right {
					flex-direction: row-reverse;
				}
			}

			.nav-logo {
				width: 70px;
				height: 70px;
				background-image: url('../image/logo.png');
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center center;
				cursor: pointer;
			}

			a {
				display: block;
				color: #fff;
				margin: 0 20px;
			}
		}
	}

	@media screen and (max-width: 1199.98px) {
		/* 데스크탑 */
	}

	@media screen and (max-width: 991.98px) {
		/* 타블렛 */
	}

	@media screen and (max-width: 767.98px) {
		/* 스마트폰 가로 */
	}

	@media screen and (max-width: 575.98px) {
		/* 스마트폰 세로 */
	}
}
