@font-face {
	font-family: 'Alata';
	src: url('./fonts/Alata-Regular.ttf') format('truetype');
	font-weight: normal;
}

@font-face {
	font-family: 'AppleSDGothicNeo';
	src: url('./fonts/AppleSDGothicNeoM.ttf') format('truetype');
	font-weight: normal;
}

@font-face {
	font-family: 'AppleSDGothicNeo';
	src: url('./fonts/AppleSDGothicNeoB.ttf') format('truetype');
	font-weight: bold;
}

// @font-face {
//   font-family: "GlacialIndifference";
//   src: url("./fonts/GlacialIndifference-Regular.otf") format("opentype");
//   font-weight: normal;
// }

// @font-face {
//   font-family: "GlacialIndifference";
//   src: url("./fonts/GlacialIndifference-Bold.otf") format("opentype");
//   font-weight: bold;
// }

// @font-face {
//   font-family: "Kollektif";
//   src: url("./fonts/Kollektif.ttf") format("truetype");
//   font-weight: normal;
// }

// @font-face {
//   font-family: "Kollektif";
//   src: url("./fonts/Kollektif-Bold.ttf") format("truetype");
//   font-weight: bold;
// }

// @font-face {
//   font-family: "Garet";
//   src: url("./fonts/Garet-Book.ttf") format("truetype");
//   font-weight: normal;
// }

// @font-face {
//   font-family: "Garet";
//   src: url("./fonts/Garet-Heavy.ttf") format("truetype");
//   font-weight: bold;
// }

$legend: calc(1.275rem + 0.3vw);
$h0: calc(2rem + 1.8vw);
$h1: calc(1.375rem + 1.5vw);
$h2: calc(1.325rem + 0.9vw);
$h3: calc(1.3rem + 0.6vw);
$h4: calc(1.275rem + 0.3vw);
$h5: 1.225rem;
$h6: 1.175em;
$display-1: calc(1.725rem + 5.7vw);
$display-2: calc(1.675rem + 5.1vw);
$display-3: calc(1.575rem + 3.9vw);
$display-4: calc(1.475rem + 2.7vw);
$close: calc(1.275rem + 0.3vw);
$content-1: calc(1rem + 0.3vw);
$content-2: calc(0.9rem + 0.15vw);
$content-3: calc(0.825rem + 0.075vw);
$content-4: calc(0.7rem);

$contents-maxwidth: 1200px;

$font_Alata: 'Alata', -apple-system, BlinkMacSystemFont, 'Malgun Gothic',
	'맑은 고딕', helvetica, 'Apple SD Gothic Neo', sans-serif;
$font_AppleSD: 'AppleSDGothicNeo', -apple-system, BlinkMacSystemFont,
	'Malgun Gothic', '맑은 고딕', helvetica, 'Apple SD Gothic Neo', sans-serif;
// $font_GlacialIndifference: "GlacialIndifference", -apple-system, BlinkMacSystemFont, "Malgun Gothic", "맑은 고딕", helvetica, "Apple SD Gothic Neo", sans-serif;
// $font_Kollektif: "Kollektif", -apple-system, BlinkMacSystemFont, "Malgun Gothic", "맑은 고딕", helvetica, "Apple SD Gothic Neo", sans-serif;
// $font_Garet: "Garet", -apple-system, BlinkMacSystemFont, "Malgun Gothic", "맑은 고딕", helvetica, "Apple SD Gothic Neo", sans-serif;

h1 {
	font-size: $h1;
	font-weight: bold;
}
h2 {
	font-size: $h2;
	font-weight: bold;
}
h3 {
	font-size: $h3;
	font-weight: bold;
}
h4 {
	font-size: $h4;
	font-weight: bold;
}
h5 {
	font-size: $h5;
	font-weight: bold;
}

a {
	text-decoration: none;
	color: #fff;
}

a:hover {
	text-decoration: none;
	color: #fff;
}

.pagination {
	--bs-pagination-color: #000;
	--bs-pagination-hover-color: #000;
	--bs-pagination-focus-color: #000;
	--bs-pagination-active-bg: #000;
	--bs-pagination-active-border-color: #000;
	--bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.25);
}
