@import '../reset.scss';

#workforus-contents {
	font-family: $font_AppleSD;
	color: #000;

	.workforus-main {
		.workforus-main-banner {
			background: url('../image/workforus/workforus-main.png');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
			height: 400px;

			h1 {
				color: #fff;
				font-size: $h0;
				padding: 12rem 0 3rem 0;
			}
		}

		.workforus-contents {
			max-width: $contents-maxwidth;
			margin: 3rem auto;

			.workforus-contents-body {
				margin: 0 3rem;

				h5 {
					margin-bottom: 3rem;
				}

				.workforus-contents-body {
					height: 300px;
					background-color: #555;
					color: #fff;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}
	}

	@media screen and (max-width: 1199.98px) {
		/* 데스크탑 */
	}

	@media screen and (max-width: 991.98px) {
		/* 타블렛 */
	}

	@media screen and (max-width: 767.98px) {
		/* 스마트폰 가로 */
	}

	@media screen and (max-width: 575.98px) {
		/* 스마트폰 세로 */
	}
}
