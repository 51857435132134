@import '../reset.scss';

#footer-contents {
	padding: 2rem 0;
	background-color: #000;
	color: #fff;

	font-family: $font_Alata;
	letter-spacing: 0.1rem;

	display: flex;
	justify-content: space-around;
	align-items: center;

	.footer-nav {
		.footer-nav-item {
			text-align: left;
			margin-bottom: 0.4rem;
		}
	}

	.footer-content {
		font-size: $content-4;
		letter-spacing: 0;
		font-family: $font_AppleSD;

		.footer-content-title {
			p {
				margin-bottom: 0.5rem;
				font-weight: bold;
			}
		}

		.footer-content-detail {
			p {
				display: inline-block;
				margin: 0 14px;
				color: #868686;
			}
		}

		.footer-content-sns {
			margin: 0.5rem 0;
			.footer-content-sns-item {
				display: inline-block;
				margin: 0 12px;
				width: 24px;
				height: 24px;
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center center;
				cursor: pointer;
			}

			.instagram {
				background-image: url('../image/footer/footer-instagram.png');
			}

			.youtube {
				background-image: url('../image/footer/footer-youtube.png');
			}
		}
	}

	.footer-logo {
		display: flex;

		.footer-logo-img {
			width: 130px;
			height: 130px;
			background-image: url('../image/logo.png');
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center center;
			cursor: pointer;
		}
	}

	@media screen and (max-width: 1199.98px) {
		/* 데스크탑 */
	}

	@media screen and (max-width: 991.98px) {
		/* 타블렛 */
	}

	@media screen and (max-width: 767.98px) {
		/* 스마트폰 가로 */
	}

	@media screen and (max-width: 575.98px) {
		/* 스마트폰 세로 */
	}
}
