@import '../reset.scss';

#press-contents {
	font-family: $font_AppleSD;
	color: #000;
	background-color: #fe8c9f;

	.press-main {
		margin: 0 auto;
		padding: 0 2rem 5rem;
		max-width: $contents-maxwidth;

		.press-main-title {
			padding: 10rem 0 5rem;
			h1 {
				color: #fff;
				font-size: calc(3rem + 2.1vw);
			}
		}

		.press-main-contents {
			.press-main-contents-article {
				height: 30rem;
				display: flex;
				justify-content: center;
				margin-bottom: 2rem;

				.press-main-contents-article-item {
					box-sizing: border-box;
					margin: 0 1rem;
					flex: 1 1;
					background-size: cover;
					background-position: center;

					width: 45%;

					border-radius: 0.5rem;
					box-shadow: 3px 3px 6px 0px #555;

					display: flex;
					flex-direction: column;
					align-content: flex-start;
					justify-content: flex-end;
					text-align: left;
					padding: 2rem;

					color: #fff;

					.press-main-contents-article-item-tag {
						p {
							display: inline;
							padding: 0.2rem 0.5rem;
							background-color: rgba(0, 0, 0, 0.7);

							font-size: $content-4;
							font-weight: bold;
						}
					}

					.press-main-contents-article-item-date {
						p {
							font-size: $content-3;
							margin: 0;
						}
					}
				}

				.press-main-contents-article-item-wrap {
					display: flex;
					flex-direction: column;
					align-items: center;
					flex: 1 1;

					box-sizing: border-box;
					width: 45%;
					height: 100%;
					margin: 0 1rem;

					.press-main-contents-article-item {
						h2 {
							font-size: $h3;
						}
					}
				}

				.left {
					height: 100%;
				}

				.right {
					&:first-child {
						margin-bottom: 1rem;
					}
					&:last-child {
						margin-top: 1rem;
					}
					width: 100%;
					height: 50%;
				}

				.dtstore {
					background-image: url('../image/press/press-article01.png');
				}

				.donated {
					background-image: url('../image/press/press-article02.png');
				}

				.pay {
					background-image: url('../image/press/press-article03.png');
				}
			}

			.press-main-contents-sns {
				display: flex;

				.press-main-contents-sns-item {
					margin: 0 1rem;

					border-radius: 1rem;
					box-shadow: 3px 3px 6px 0px #555;

					flex: 1 1;

					background-color: #fff;

					.sns-item-auther {
						border-radius: 0.5rem 0.5rem 0 0;

						text-align: left;

						padding: 0.8rem;

						.sns-item-auther-image {
							display: inline-block;
							width: 60px;
							height: 60px;
							background-size: cover;
							background-position: center;

							vertical-align: middle;

							margin-right: 1rem;
						}

						.sns-item-auther-name {
							display: inline-block;

							font-size: $content-2;
							font-weight: bold;
						}

						.auther01 {
							background-image: url('../image/press/press-profile01.png');
						}

						.auther02 {
							background-image: url('../image/press/press-profile02.png');
						}

						.auther03 {
							background-image: url('../image/press/press-profile03.png');
						}
					}

					.sns-item-image {
						width: 100%;
						padding-bottom: 100%;
						object-fit: contain;
						background-size: cover;
						background-position: center;
					}

					.instagram01 {
						background-image: url('../image/press/press-instagram01.png');
					}

					.instagram02 {
						background-image: url('../image/press/press-instagram02.png');
					}

					.instagram03 {
						background-image: url('../image/press/press-instagram03.png');
					}

					.sns-item-menu {
						display: flex;
						justify-content: space-between;
						padding: 0.8rem 0.8rem 0.3rem;

						.sns-item-menu-button {
							margin: 0 0.3rem;
							display: inline-block;
							object-fit: contain;
							background-size: contain;
							background-position: center;
							background-repeat: no-repeat;

							width: 30px;
							height: 30px;
						}
						.sns-item-menu-left {
							.heart {
								background-image: url('../image/press/press-instagram-heart.png');
							}

							.comment {
								background-image: url('../image/press/press-instagram-comment.png');
							}

							.share {
								background-image: url('../image/press/press-instagram-share.png');
							}
						}

						.bookmark {
							background-image: url('../image/press/press-instagram-bookmark.png');
						}
					}

					.sns-item-contents {
						padding: 0 0.8rem;
						text-align: left;
						font-size: $content-3;
						.sns-item-contents-like {
						}

						.sns-item-contents-hash {
							color: #3395ff;
							margin: 0;
						}
						.sns-item-contents-date {
							color: #939393;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 1199.98px) {
		/* 데스크탑 */
	}

	@media screen and (max-width: 991.98px) {
		/* 타블렛 */
	}

	@media screen and (max-width: 767.98px) {
		/* 스마트폰 가로 */
	}

	@media screen and (max-width: 575.98px) {
		/* 스마트폰 세로 */
	}
}
