@import '../reset.scss';

#unlock-contents {
	background-color: #000;
	font-family: $font_AppleSD;
	color: #000;

	position: absolute;
	width: 100%;
	height: 100%;

	z-index: 100;

	.unlock-main {
		position: absolute;
		top: 50vh;
		left: 50vw;
		transform: translate(-50%, -50%);
		width: 100%;
		max-width: 500px;
		padding: 0 20px;
		box-sizing: border-box;

		.unlock-main-logo {
			width: 300px;
			height: 300px;

			background-image: url('../image/logo.png');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;

			margin: 0 auto 2rem;
		}
	}

	@media screen and (max-width: 1199.98px) {
		/* 데스크탑 */
	}

	@media screen and (max-width: 991.98px) {
		/* 타블렛 */
	}

	@media screen and (max-width: 767.98px) {
		/* 스마트폰 가로 */
	}

	@media screen and (max-width: 575.98px) {
		/* 스마트폰 세로 */
	}
}

.correct {
	display: none;
}
