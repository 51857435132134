@import '../reset.scss';

#franchising-contents {
	font-family: $font_AppleSD;
	color: #000;

	.franchising-main {
		max-width: $contents-maxwidth;
		height: 100vh;
		margin: 0 auto;
		padding-top: 5rem;

		.franchising-main-wrap {
			display: flex;
			align-items: center;
			margin: 0 2rem;
			height: 100%;
		}

		.franchising-main-image {
			width: 600px;
			height: 600px;

			background-image: url('../image/franchising/franchising-main.png');
			background-size: cover;
			background-position: center;

			flex: 1 1;
		}

		.franchising-main-body {
			flex: 1 1;

			h1 {
				margin-bottom: 5rem;
			}

			Button {
				width: 20rem;
				font-size: $h3;
			}
		}
	}

	@media screen and (max-width: 1199.98px) {
		/* 데스크탑 */
	}

	@media screen and (max-width: 991.98px) {
		/* 타블렛 */
	}

	@media screen and (max-width: 767.98px) {
		/* 스마트폰 가로 */
	}

	@media screen and (max-width: 575.98px) {
		/* 스마트폰 세로 */
	}
}
