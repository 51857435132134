@import '../reset.scss';

#contactus-contents {
	font-family: $font_AppleSD;
	color: #000;

	.contactus-main {
		background-image: url('../image/contactus/contactus-main.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;

		.contactus-main-body {
			max-width: $contents-maxwidth;
			margin: 0 auto;
			color: #fff;

			h1 {
				padding: 10rem 0 2rem 0;
			}

			h3 {
				margin-bottom: 3rem;
			}

			.contactus-main-body-form {
				margin: 0 auto;
				padding-bottom: 5rem;
				max-width: 500px;

				Button {
					font-weight: bold;
					width: 100%;
				}
			}
		}
	}

	@media screen and (max-width: 1199.98px) {
		/* 데스크탑 */
	}

	@media screen and (max-width: 991.98px) {
		/* 타블렛 */
	}

	@media screen and (max-width: 767.98px) {
		/* 스마트폰 가로 */
	}

	@media screen and (max-width: 575.98px) {
		/* 스마트폰 세로 */
	}
}
