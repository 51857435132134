@import '../reset.scss';

#menu-contents {
	font-family: $font_AppleSD;
	color: #000;

	.menu-paragraph {
		margin-bottom: 5rem;

		.menu-paragraph-image {
			width: 100%;
			height: 300px;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
			background-color: #000;
		}

		.menu-01 {
			background-image: url('../image/menu/menu-01.png');
		}

		.menu-02 {
			background-image: url('../image/menu/menu-02.png');
		}

		.menu-03 {
			background-image: url('../image/menu/menu-03.png');
		}

		.menu-04 {
			background-image: url('../image/menu/menu-04.png');
		}

		.menu-paragraph-content {
			display: flex;
			justify-content: center;
			text-align: left;

			.menu-paragraph-content-block {
				margin: 1rem 0.5rem;
				padding: 1rem;
				width: 330px;
				background-color: #f5f0ea;

				.menu-paragraph-content-block-title {
				}

				.menu-paragraph-content-block-detail {
					.menu-paragraph-content-block-detail-item-head {
						display: flex;
						justify-content: space-between;
						font-weight: bold;

						p {
							margin-bottom: 0.2rem;
						}
					}

					.menu-paragraph-content-block-detail-Description {
						width: 80%;
						font-size: $content-3;
						line-height: 1rem;
					}
				}

				.menu-paragraph-content-block-column {
					width: 298px;
				}
			}

			.drinks {
				.menu-paragraph-content-block-detail {
					margin-bottom: 1rem;
				}
			}

			.minibagels,
			.spread,
			.coldbeverages,
			.alcoholicbeverages {
				.menu-paragraph-content-block-detail {
					margin-bottom: 0.5rem;
				}
			}

			.minipretzels {
				h3:not(:first-of-type) {
					margin-top: 1rem;
				}
			}

			.coldbeverages.menu-paragraph-content-block {
				display: flex;
				justify-content: space-between;
				width: calc(660px + 1rem);
			}

			.alcoholicbeverages {
				.menu-paragraph-content-block-title {
					font-size: $h4;
				}
			}
		}
	}

	@media screen and (max-width: 1199.98px) {
		/* 데스크탑 */
	}

	@media screen and (max-width: 991.98px) {
		/* 타블렛 */
	}

	@media screen and (max-width: 767.98px) {
		/* 스마트폰 가로 */
	}

	@media screen and (max-width: 575.98px) {
		/* 스마트폰 세로 */
	}
}
