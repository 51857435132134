@import '../reset.scss';

#events-contents {
	font-family: $font_AppleSD;
	color: #000;

	.events-main {
		.events-main-banner {
			background: url('../image/events/events-main.png');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
			height: 400px;

			h1 {
				color: #fff;
				font-size: $h0;
				padding: 12rem 0 3rem 0;
			}
		}

		.events-contents {
			max-width: $contents-maxwidth;
			margin: 3rem auto;

			.events-contents-wrap {
				margin: 0 3rem;

				.events-contents-post {
					margin-bottom: 4rem;
					text-align: left;

					.events-contents-post-sub {
						font-size: $content-2;
						color: #777;
						font-weight: bold;
						p {
							display: inline;
							margin-bottom: 0;
							&:first-child {
								margin-right: 2rem;
							}
						}
					}

					.events-contents-post-underline {
						width: 100%;
						height: 2px;
						background-color: #555;
						margin-bottom: 1rem;
					}

					.events-contents-post-img {
						width: 100%;
						height: 500px;
						background-color: #ccc;
						background-size: cover;
						background-position: center center;
						margin-bottom: 1rem;

						background-image: url('../image/events/events-post.png');
					}
				}

				.events-contents-list {
					Table {
						font-size: $content-2;

						.table-title {
							text-align: left;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 1199.98px) {
		/* 데스크탑 */
	}

	@media screen and (max-width: 991.98px) {
		/* 타블렛 */
	}

	@media screen and (max-width: 767.98px) {
		/* 스마트폰 가로 */
	}

	@media screen and (max-width: 575.98px) {
		/* 스마트폰 세로 */
	}
}
