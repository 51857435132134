@import '../reset.scss';

#home-contents {
	font-family: $font_AppleSD;
	color: #000;

	.home-main {
		background: url('../image/home/home-main.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		height: 500px;

		h1 {
			color: #fff;
			padding: 9rem 0 3rem 0;
		}

		.home-main-logo {
			margin: 0 auto;
			width: 200px;
			height: 200px;
			background-image: url('../image/logo.png');
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center center;
		}
	}

	.home-main-detail {
		margin: 5rem 0;
		p {
			font-weight: bold;
			font-size: $h4;
			margin-bottom: -0.3rem;
		}
	}

	.home-content {
		max-width: 1200px;
		margin: 0 auto 5rem;

		.row {
			margin: 5rem 0 2rem;
		}

		.reverse {
			flex-direction: row-reverse;
		}

		.col {
			display: flex;
			flex-direction: column;
			justify-content: center;
			flex-grow: 2;

			h2 {
				margin-bottom: 3rem;
			}
		}

		.card-image {
			flex-grow: 1;
		}

		.home-content-image {
			height: 350px;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center center;
			cursor: pointer;
		}

		.aboutus {
			background-image: url('../image/home/home-aboutus.png');
		}

		.menu {
			background-image: url('../image/home/home-menu.png');
		}
	}

	button {
		margin: 0 1rem;
		width: 12rem;
		font-size: $h4;
	}

	@media screen and (max-width: 1199.98px) {
		/* 데스크탑 */
	}

	@media screen and (max-width: 991.98px) {
		/* 타블렛 */
	}

	@media screen and (max-width: 767.98px) {
		/* 스마트폰 가로 */
	}

	@media screen and (max-width: 575.98px) {
		/* 스마트폰 세로 */
	}
}
