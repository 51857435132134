@import '../reset.scss';

#aboutus-contents {
	font-family: $font_AppleSD;
	color: #000;

	.aboutus-main {
		.aboutus-main-banner {
			background: url('../image/aboutus/aboutus-main.png');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
			height: 400px;

			h1 {
				color: #fff;
				font-size: $h0;
				padding: 12rem 0 3rem 0;
			}
		}

		.aboutus-contents {
			max-width: $contents-maxwidth;
			margin: 3rem auto;

			.aboutus-contents-body {
				margin: 0 3rem;

				h1 {
					font-size: $h0;
					margin-bottom: 3rem;
				}

				p {
					font-size: $content-1;
					font-weight: bold;

					margin-bottom: 2rem;
					line-height: 1.4rem;
				}

				Button {
					width: 15rem;
					font-size: $h4;
					font-weight: bold;
				}
			}
		}
	}

	@media screen and (max-width: 1199.98px) {
		/* 데스크탑 */
	}

	@media screen and (max-width: 991.98px) {
		/* 타블렛 */
	}

	@media screen and (max-width: 767.98px) {
		/* 스마트폰 가로 */
	}

	@media screen and (max-width: 575.98px) {
		/* 스마트폰 세로 */
	}
}
