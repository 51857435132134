@import '../reset.scss';

#shop-contents {
	font-family: $font_AppleSD;
	color: #000;

	.shop-main {
		.shop-main-banner {
			background: url('../image/shop/shop-main.png');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
			height: 400px;

			h1 {
				color: #fff;
				font-size: $h0;
				padding: 12rem 0 3rem 0;
			}
		}

		.shop-list {
			max-width: $contents-maxwidth;
			margin: 3rem auto;

			.shop-list-row {
				display: flex;
				justify-content: center;

				margin-bottom: 2rem;

				.shop-list-item {
					margin: 0 1rem;
					width: 20vw;

					$border-radius: 3vw;
					border-radius: $border-radius $border-radius 0 0;

					box-shadow: 3px 3px 6px 0px #ccc;

					.shop-list-item-image {
						background-repeat: no-repeat;
						background-size: cover;
						background-position: center center;
						width: 100%;
						height: 20vw;
						object-fit: contain;

						border-radius: $border-radius $border-radius 0 0;
					}

					.shop-list-item-detail {
						padding: 1rem;

						.shop-list-item-detail-title {
							text-align: left;
							font-size: $h6;
						}
						.shop-list-item-detail-price {
							text-align: left;
							font-size: $content-3;
						}

						Button {
							width: 100%;
							height: 2.5rem;
							font-size: $content-2;
							background-color: #000;
							color: #fff;
							border: none;
							border-radius: 5rem;

							font-weight: bold;
						}
					}

					.mug {
						background-image: url('../image/shop/shop-mug.png');
					}
					.tumbler {
						background-image: url('../image/shop/shop-tumbler.png');
					}
					.ecobag {
						background-image: url('../image/shop/shop-ecobag.png');
					}
					.siliconetumbler {
						background-image: url('../image/shop/shop-siliconetumbler.png');
					}
					.handletumbler {
						background-image: url('../image/shop/shop-handletumbler.png');
					}
					.thermaltumbler {
						background-image: url('../image/shop/shop-thermaltumbler.png');
					}
					.nettedecobag {
						background-image: url('../image/shop/shop-nettedecobag.png');
					}
					.apron {
						background-image: url('../image/shop/shop-apron.png');
					}
					.cap {
						background-image: url('../image/shop/shop-cap.png');
					}
					.tshirts {
						background-image: url('../image/shop/shop-tshirts.png');
					}
					.towel {
						background-image: url('../image/shop/shop-towel.png');
					}
					.umbrella {
						background-image: url('../image/shop/shop-umbrella.png');
					}
					.diary {
						background-image: url('../image/shop/shop-diary.png');
					}
					.coolerbag {
						background-image: url('../image/shop/shop-coolerbag.png');
					}
					.beans {
						background-image: url('../image/shop/shop-beans.png');
					}
					.bagelslicer {
						background-image: url('../image/shop/shop-bagelslicer.png');
					}
				}
			}
		}
	}

	@media screen and (max-width: 1199.98px) {
		/* 데스크탑 */
	}

	@media screen and (max-width: 991.98px) {
		/* 타블렛 */
	}

	@media screen and (max-width: 767.98px) {
		/* 스마트폰 가로 */
	}

	@media screen and (max-width: 575.98px) {
		/* 스마트폰 세로 */
	}
}
